import React, {useEffect} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroSlider from "../components/heroSlider";
import {graphql} from "gatsby";
import { Helmet } from 'react-helmet';
import StrapiComponentRender from '../components/strapiComponentRender';

const IndexPage = ({data}) => {
  const slides = data.strapiHomepage.slider.slides;
  const content = data.strapiHomepage.content;
  const seo = data.strapiHomepage.SEO;

  return (
    <Layout>
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
      />
      <HeroSlider slides={slides.map(slide => ({title: slide.title, url: slide.image.publicURL}))}/>
      {content.map(contentItem => (
        <StrapiComponentRender content={contentItem} />
      ))}
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query HomepageQuery {
    strapiHomepage {
      slider {
        slides {
          id
          image {
            publicURL
          }
          title
        }
      }
      content {
        backgroundcolor
        textcolumn_left
        textcolumn_middle
        textcolumn_right
        title_textarea_three_columns
        service {
          title
          content
          icon {
            publicURL
          }
        }
        title_services
        button {
          link
          text
        }
      }
      SEO {
        description
        keywords
        title
      }
    }
  }
`
